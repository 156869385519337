import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'es6-promise/auto';

import App from 'next/app';

import '../static/styles/_variables.scss';
import '../static/styles/_mixins.scss';
import '../static/styles/normalize.scss';
import '../static/styles/global.scss';
import '../static/styles/icomoon.scss';
import '../static/styles/optanon-ob.scss';
import '../static/styles/header.scss';
import '../static/styles/modal.scss';
import '../static/styles/one-page-scroll.scss';
import '../static/styles/bazaarvoice.scss';

export default App;
